import React from "react";

const About = () => {
  return (
    <div className="about">
      <div className="about-left">
        <img src="/assets/about-img.jpg" alt="" />
      </div>

      <div className="about-right">
        <h3>OUR SHORT STORY</h3>
        <h1>
          Know more about <span>Lakesi Global Travels</span>
        </h1>
        <p>
          Lakesi Global Travel and Tours was established in 1994 in Baltimore
          Maryland USA, we have over two decades of success in the travel and
          tourism industry. We are proud of our track record in customer
          satisfaction, customer retention and continous referrals.
          <br />
          Our mission is to make travel and vacation plans simple, affordable,
          and enjoyable by providing our customers with world-class
          professionally managed skills in travel and tourism.
          <br />
          Our vision is to render excellent, indispensable, reliable, and
          trusted services to all our customers with affordable fares and
          packaged deals that are unobtainable globally.
        </p>
      </div>
    </div>
  );
};

export default About;
