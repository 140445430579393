import React from "react";

// PACKAGES
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "normalize.css/normalize.css";
import Typewriter from "typewriter-effect";

// COMPONENTS
import content from "../../constants/hero";

const Hero = () => (
  <div>
    <Slider className="slider-wrapper" autoplay={7000} touchDisabled={true}>
      {content.map((item, index) => (
        <div
          key={index}
          className="slider-content"
          style={{ background: `url('${item.image}') no-repeat center center` }}
        >
          <div className="inner">
            <h4>
              <Typewriter
                options={{
                  strings: [
                    `We make travels and vacations plans simple and affordable`,
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h4>
            <h1 data-aos="fade-down" data-aos-once="true">
              <span>{item.start}</span> {item.title}
            </h1>
            <p data-aos="fade-up" data-aos-once="true">
              {item.description}
            </p>
            {/* <Link to="/contact">
              <button>Book your travel</button>
            </Link> */}
          </div>
        </div>
      ))}
    </Slider>
  </div>
);

export default Hero;
