export const how = [
  {
    id: 1,
    icon: "/assets/how-one.png",
    header: "Technology",
    text: `In-house tools covering profile management, reporting and more`,
  },
  {
    id: 2,
    icon: "/assets/how-two.png",
    header: "Global Agency",
    text: `100+ trusted, reputable local agencies in 72 markets on 6 continents`,
  },
  {
    id: 3,
    icon: "/assets/how-three.png",
    header: "Leadership Team",
    text: `Passionate about business travel, with decades of multi-industry experience`,
  },
];
