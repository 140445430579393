import React from "react";
import Card from "./../../common/Card";
import { vacation } from "./../../constants/vacation";

const VacationSpot = () => {
  return (
    <div className="vacation-spot">
      <h1 data-aos="fade-down" data-aos-once="true">
        Vacation Spots In Nigeria
      </h1>
      <p data-aos="fade-up" data-aos-once="true">
        Prepare yourself let's enjoy the beauty of the world
      </p>
      <div
        className="vacation-center"
        data-aos="fade-down"
        data-aos-once="true"
      >
        {vacation.map((item) => {
          return <Card key={item.id} {...item} />;
        })}
      </div>
    </div>
  );
};

export default VacationSpot;
