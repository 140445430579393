import React from "react";
import { FaCheck } from "react-icons/fa";

const Ahead = () => {
  return (
    <div className="ahead">
      <div className="ahead-left">
        <h1 data-aos="fade-down" data-aos-once="true">
          Best deals available for your next cruises
        </h1>
        <ul
          className="ahead-left-list"
          data-aos="fade-right"
          data-aos-once="true"
        >
          <li>
            <FaCheck className="check" /> Car Rentals
          </li>
          <li>
            <FaCheck className="check" /> Vacation Homes
          </li>
          <li>
            <FaCheck className="check" /> Hotel Reservations
          </li>
          <li>
            <FaCheck className="check" /> Chauffeur in Lagos & Abuja
          </li>
        </ul>

        {/* <button>Message Us</button> */}
      </div>

      <div className="ahead-right">
        <div className="video-section">
          <video width="100%" height="100%" controls>
            <source src="/assets/lakesi.mp4" type="video/mp4" />
            <source src="/assets/lakesi.mp4" type="video/ogg" />
            Your browser does not support this video.
          </video>
        </div>
      </div>
    </div>
  );
};

export default Ahead;
