import React from "react";
// import FooterComponent from "@Ayodejioladimeji/footer-component";
import {
  FaWhatsapp,
  FaFacebookF,
  FaInstagram,
  FaMicrophone,
  FaEnvelope,
} from "react-icons/fa";
import { BsMapFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-center">
          <div className="footer-card first-child">
            <img src="/assets/logo1.png" alt="" />
            <p>
              To make travel and vacation plans simple, affordable, and
              enjoyable by providing our customers with world-class
              professionally managed skills in travel and tourism.
            </p>
          </div>

          <div className="footer-card card-about">
            <ul>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
            </ul>
          </div>

          <div className="footer-card">
            <div className="footer-data">
              <BsMapFill className="footer-data-icons" />
              <div className="address">
                3804 Twin Lakes Court Windsor Mill, Maryland 21244
              </div>
            </div>
            <div className="footer-data">
              <FaMicrophone className="footer-data-icons" />
              <div>
                410-448-5656 <br /> 443-739-2626
              </div>
            </div>
            <div className="footer-data">
              <FaEnvelope className="footer-data-icons" />
              <div>flylakesi@gmail.com</div>
            </div>
          </div>

          <div className="footer-card card-image">
            <div className="footer-card-div">
              <div className="footer-image-box">
                <img src="/assets/about.jpg" alt="" />
              </div>
              <div className="footer-image-box">
                <img src="/assets/agent2.jpg" alt="" />
              </div>
              <div className="footer-image-box">
                <img src="/assets/agent3.jpg" alt="" />
              </div>
              <div className="footer-image-box">
                <img src="/assets/agent4.jpg" alt="" />
              </div>
              <div className="footer-image-box">
                <img src="/assets/agent5.jpg" alt="" />
              </div>
              <div className="footer-image-box">
                <img src="/assets/travel.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-social">
          <div className="social-div">
            <FaFacebookF className="contact-icon" />
          </div>

          <div className="social-div">
            <FaWhatsapp className="contact-icon" />
          </div>

          <div className="social-div">
            <FaInstagram className="contact-icon" />
          </div>
        </div>

        <div className="footer-reserved">
          <span>
            All rights reserved copyright {new Date().getFullYear()} | Lakesi
            Global Travels{" "}
          </span>
        </div>
      </div>
    </>
  );
};

export default Footer;
