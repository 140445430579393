import React from "react";
import { Link } from "react-router-dom";

const Experience = () => {
  return (
    <div className="experience">
      {/* <div className="experience-center"> */}
      <div className="experience-left">
        <h3>EXTRAORDINARY EXPERIENCES,</h3>
        <h1>Designed Just For You</h1>
        <p>
          At Lakesi Global Travels, we excel at helping you get your vacation
          planned. Not just any vacation, but exceptional vacations filled with
          inspiring and life-enriching experiences. Our approach is different.
          We don’t plan any trips ourselves. Instead, we match you with 2 or 3
          leading travel specialists who are the most qualified to make your
          dream trip happen. They then compete to design your ideal itinerary.
        </p>

        <Link to="/contact">
          <button>Get Intouch</button>
        </Link>
      </div>

      <div className="experience-right">
        <img src="/assets/travel.png" alt="travel" />
      </div>
    </div>
    // </div>
  );
};

export default Experience;
