import React from "react";
import { Helmet } from "react-helmet";
import favicon from "../assets/airplane.png";

const SEO = ({ title }) => {
  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={`${title} | Lakesi Global Travel`}
    >
      <meta name="image" content={favicon} />
      <meta
        name="description"
        content="To make travel and vacation plans simple, affordable, and
              enjoyable by providing our customers with world-class
              professionally managed skills in travel and tourism."
      />
      <link rel="shortcut icon" href={favicon} />
    </Helmet>
  );
};

export default SEO;
