import React from "react";

const HowCard = ({ ...item }) => {
  const { icon, header, text } = item;
  return (
    <div className="how-card">
      <div className="how-card-img">
        <img src={icon} alt="" />
      </div>

      <h2>{header}</h2>
      <small>{text}</small>
    </div>
  );
};

export default HowCard;
