const people = [
  {
    id: 1,
    name: "Lorance Joy",
    title: "Jacksonville, NC",
    quote: `We just returned from London and we are thanking you for the great job you did in putting our trip together. We are very grateful for everything you did for us and everything went very well. We’re safe and sound back home thinking about the joy we experienced. `,
  },
  {
    id: 2,
    name: "Adebunmi Sunday",
    title: "COO Dia Multiglobal Concepts Limited",
    quote: `I want to thank you for your effort in solving a problem that I had with travelling, you really helped my life and made things easy for me,You had handled the problem with such a great attitude and promptly solved everything.
            I would strongly recommend Lakesi Global Travel and Tours for anyone that is planning to travel`,
  },
  {
    id: 3,
    name: "Michael Ayeni",
    title: "MD Pasture Solid Minerals",
    quote: `Thank you for your GREAT service and attention to detail. I have always preferred using a live person–like yourself–to make our travel arrangements. We have done quite a bit of traveling and it is just so much more reassuring to deal with a person than an abstract thing like the computer.`,
  },
  //   {
  //     id: 4,
  //     name: "SUD",
  //     title: "MD Gslogistics ",
  //     quote:
  //       "Lighthill is a Company I can leave my money with and go sleep peacefully, that’s how much i trust them. I have been in business with them for over 5years and never had a reason to regret it",
  //   },
  //   {
  //     id: 5,
  //     name: "Mr Bamidele",
  //     title: "MD Perspective",
  //     quote:
  //       "I have always experienced excellent service dealing with Lighthill and their swift response is top notch .",
  //   },
];

export default people;
