import React from "react";

// PACKAGES
import { FaTimesCircle } from "react-icons/fa";

// COMPONENTS
import Links from "../../constants/links";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <aside className={`sidebar ${isOpen ? "show-sidebar" : ""} `}>
      {/* <button className="close-btn" onClick={toggleSidebar}>
        <FaTimesCircle />
      </button> */}

      <div className="side-container" onClick={toggleSidebar}>
        <Links styleClass={`${isOpen ? "sidebar-links" : ""}`} />
      </div>
    </aside>
  );
};

export default Sidebar;
