import React from "react";
import { years } from "./../../constants/years";

const Years = () => {
  return (
    <div className="years">
      {years.map((item) => {
        const { id, icon, header, text } = item;
        return (
          <div className="years-card" key={id}>
            <div className="years-card-img">
              <img src={icon} alt="" />
            </div>

            <h1>{header}</h1>
            <p>{text}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Years;
