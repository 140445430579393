import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import BreadCumb from "./../common/breadcumb/BreadCumb";
import AboutUs from "../components/about/About";
import Years from "../components/years/Years";
import Why from "../components/why/Why";
import Getstarted from "../components/getstarted/Getstarted";
import Testimonial from "../components/testimonials/Testimonial";
import LetsTalk from "./../components/letstalk/LetsTalk";
import Loader from "../utils/Loader";
import SEO from "../common/SEO";

const About = () => {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let loader = setInterval(() => {
      setLoading(false);
    }, 2500);
    return () => {
      clearInterval(loader);
    };
  }, [pathname]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <SEO title="About Us" />
      <BreadCumb
        img="/assets/about.jpg"
        title="About Us"
        text="We are proud of our track record in customer satisfaction, customer retention and continous referrals."
      />
      <AboutUs />
      <Years />
      <Why />
      <Getstarted />
      <Testimonial />
      <LetsTalk />
    </>
  );
};

export default About;
