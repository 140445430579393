import React from "react";
import { Link } from "react-router-dom";

const LetsTalk = () => {
  return (
    <div className="lets-talk">
      <div className="lets-talk-center">
        <h2>Have Some Questions About Travels & Tours ?</h2>
        <Link to="/contact">
          <button>Let's Talk</button>
        </Link>
      </div>
    </div>
  );
};

export default LetsTalk;
