import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import BreadCumb from "./../common/breadcumb/BreadCumb";
import Contacts from "../components/contact/Contact";
import Contact2 from "../components/contact2/Contact2";
import Loader from "./../utils/Loader";
import SEO from "./../common/SEO";

const Contact = () => {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let loader = setInterval(() => {
      setLoading(false);
    }, 2000);
    return () => {
      clearInterval(loader);
    };
  }, [pathname]);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <SEO title="Contact Us" />
      <BreadCumb
        img="/assets/contact.jpg"
        title="Contact Us"
        text="Prepare yourself and lets explore the beauty of the world, We have many special offters especially for you"
      />
      <Contacts />
      <Contact2 />
    </>
  );
};

export default Contact;
