export const prepare = [
  {
    id: 1,
    icon: "/assets/services-five.png",
    header: "Reliable Booking System",
    text: "Your booking system! We offer a booking, scheduling help.",
  },
  {
    id: 2,
    icon: "/assets/services-one.png",
    header: "Get Travel Insurance",
    text: "Travel insurance is intended to cover medical expenses, trip cancellation.",
  },
  {
    id: 3,
    icon: "/assets/services-six.png",
    header: "World Class Service",
    text: "We all want it. If we run a business, we all want to give the impression.",
  },
];
