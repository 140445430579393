import React from "react";
import { Link } from "react-router-dom";

const Getstarted = () => {
  return (
    <div className="get">
      <div className="getstarted">
        <h3 data-aos="fade-down" data-aos-once="true">
          SAVE TIME, SAVE MONEY
        </h3>
        <h1 data-aos="fade-up" data-aos-once="true">
          Let’s Get Started
        </h1>
        <p data-aos="fade-down" data-aos-once="true">
          We have the knowledge, experience, and expertise to take care of all
          your travel needs. Reach out and we'll send the best deals to you
        </p>

        <Link to="/contact">
          <button data-aos="fade-up" data-aos-once="true">
            Get In Touch
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Getstarted;
