import React from "react";
import AnyCountry from "../components/anycountry/AnyCountry";
import Hero from "../components/hero/Hero";
import Plan from "../components/plan/Plan";
import Prepare from "../components/prepare/Prepare";
import Values from "../components/values/Values";
import LetsTalk from "./../components/letstalk/LetsTalk";
import Testimonial from "./../components/testimonials/Testimonial";
import Getstarted from "./../components/getstarted/Getstarted";
import Ahead from "./../components/ahead/Ahead";
import VacationSpot from "../components/vacationspot/VacationSpot";
import SEO from "./../common/SEO";

const Home = () => {
  return (
    <>
      <SEO title="Homepage" />
      <Hero />
      <Prepare />
      <AnyCountry />
      <Plan />
      <Values />
      <VacationSpot />
      <Ahead />
      <Getstarted />
      <Testimonial />
      <LetsTalk />
    </>
  );
};

export default Home;
