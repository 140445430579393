import React from "react";
import { prepare } from "./../../constants/prepare";

const Prepare = () => {
  return (
    <div className="prepare">
      <h1 data-aos="fade-down" data-aos-once="true">
        Prepare for your travel
      </h1>
      <p data-aos="fade-up" data-aos-once="true">
        Prepare to spend the most amazing time out.
      </p>

      <div className="prepare-center">
        {prepare.map((item) => {
          const { id, icon, header, text } = item;
          return (
            <div
              key={id}
              className="prepare-card"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div className="prepare-image">
                <img src={icon} alt="" />
              </div>

              <h2>{header}</h2>
              <small>{text}</small>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Prepare;
