import React from "react";
import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";

const Contact = () => {
  return (
    <div className="contact">
      <div className="contact-card">
        <div className="contact-img">
          <img src="/assets/address.png" alt="" />
        </div>

        <h2>Our Address</h2>
        <small>3804 Twin Lakes Court</small>
        <small>Windsor Mill, Maryland 21244</small>
      </div>

      <div className="contact-card">
        <div className="contact-img">
          <img src="/assets/phone.png" alt="" />
        </div>

        <h2>Phone & Email</h2>
        <small>410-448-5656</small>
        <small>443-739-2626</small>
        <small>flylakesi@gmail.com</small>
      </div>

      <div className="contact-card">
        <div className="contact-img">
          <img src="/assets/stay.png" alt="" />
        </div>
        <h2>Stay In Touch</h2>
        <div className="contact-social">
          <div className="social-div">
            <FaFacebookF className="contact-icon" />
          </div>

          <div className="social-div">
            <FaWhatsapp className="contact-icon" />
          </div>

          <div className="social-div">
            <FaInstagram className="contact-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
