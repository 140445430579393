import React, { useState } from "react";

// PACKAGES
import { BiMenuAltRight } from "react-icons/bi";

// COMPONENTS
import PageLinks from "../../constants/links";
import { Link } from "react-router-dom";
import { BsTelephonePlus } from "react-icons/bs";
import Toggle from "../../common/Toggle";
import { FaTimesCircle } from "react-icons/fa";

const Navbar = ({ toggleSidebar, isOpen }) => {
  const [navbar, setNavbar] = useState(false);

  // Changing the navbar color on scroll
  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <nav className={navbar ? "navbar active" : "navbar"}>
      <div className="nav-center">
        {/* THE SECTION OF THE NAV HEADER LOGO */}
        <div>
          <Link to="/" className="nav-header">
            <img src="/assets/logos.svg" alt="logo" />
            <div className="header-brand">
              LAKESI GLOBAL <small>TRAVEL & TOURS</small>
            </div>
          </Link>
        </div>

        {/* THE SECTION OF THE PAGE LINKS */}
        <PageLinks styleClass="nav-links" />

        <div className="nav-display">
          <p>
            <BsTelephonePlus className="telephone" />
            +1(410-448-5656)
          </p>
          <Toggle />
        </div>

        {!isOpen ? (
          <button className="toggle-icon" onClick={toggleSidebar}>
            <BiMenuAltRight />
          </button>
        ) : (
          <button className="toggle-icon" onClick={toggleSidebar}>
            <FaTimesCircle />
          </button>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
