import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Experience from "../components/experience/Experience";
import How from "../components/how/How";
import LetsTalk from "../components/letstalk/LetsTalk";
import BreadCumb from "./../common/breadcumb/BreadCumb";
import Service from "./../components/services/Services";
import Loader from "./../utils/Loader";
import SEO from "./../common/SEO";

const Services = () => {
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    let loader = setInterval(() => {
      setLoading(false);
    }, 2000);
    return () => {
      clearInterval(loader);
    };
  }, [pathname]);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <SEO title="Services" />
      <BreadCumb
        img="/assets/agent1.jpg"
        title="Our Services"
        text="We always make our customers happy by providing as many choices as possible"
      />
      <Service />
      <How />
      <Experience />
      <LetsTalk />
    </>
  );
};

export default Services;
