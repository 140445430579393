import React from "react";
import { Link } from "react-router-dom";

const AnyCountry = () => {
  return (
    <div className="any-countrys">
      <div className="country-left">
        <h3 data-aos="fade-down" data-aos-once="true">
          Travel to
        </h3>
        <h2 data-aos="fade-down" data-aos-once="true">
          Any country
        </h2>

        <p data-aos="fade-up" data-aos-once="true">
          At Lakesi Global Travels, our passion is what drives us. Whether you
          want to hunt for a rare truffles in Tuscany, track the Great Migration
          on a mobile camping safari or learn aboriginal art from a Maori Chief
          in New Zealand or you want to explore beautiful places in Nigeria - We
          are here to arrange it for you
        </p>

        <Link to="/contact">
          <button data-aos="fade-right" data-aos-once="true">
            Learn More
          </button>
        </Link>
      </div>

      <div className="country-right">
        <img
          src="/assets/lakesi.svg"
          alt=""
          data-aos="zoom-in"
          data-aos-once="true"
        />
      </div>
    </div>
  );
};

export default AnyCountry;
