import React from "react";
import { values } from "./../../constants/values";

const Values = () => {
  return (
    <div className="values">
      <div className="values-center">
        <h3 data-aos="fade-up" data-aos-once="true">
          Why choose our travel agency?
        </h3>
        <h1 data-aos="fade-down" data-aos-once="true">
          our core values
        </h1>
        <p data-aos="fade-up" data-aos-once="true">
          Our thoughtful team of knowledgeable experts are here to take care of
          every needs, from the second you contact us to when you return.
        </p>

        <div className="values-div">
          {values.map((item) => {
            const { icon, header, text } = item;
            return (
              <div
                className="values-card"
                data-aos="fade-right"
                data-aos-once="true"
              >
                <div className="values-image">
                  <img src={icon} alt="" />
                </div>

                <h2>{header}</h2>
                <small>{text}</small>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Values;
