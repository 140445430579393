export const values = [
  {
    id: 1,
    icon: "/assets/values-one.png",
    header: "Accomodation",
    text: `No matter where you are travelling, we are here to help you get the best deals in Hotels around the world`,
  },
  {
    id: 2,
    icon: "/assets/values-two.png",
    header: "Transportation",
    text: `Whether you are travelling through Air, Sea or Land, we can arrange transportation according to.`,
  },
  {
    id: 3,
    icon: "/assets/values-three.png",
    header: "Expert Trip Planning",
    text: `Our Talented and Expert Trip Planning Team can make itinerary that suits our clients the best. We will make your travel`,
  },
];
