export const vacation = [
  {
    id: 1,
    icon: "/assets/camas.jpg",
    header: "Camas Hotel & Suite",
    text: `Hotels`,
  },
  {
    id: 2,
    icon: "/assets/lekki.jpg",
    header: "Lekki Conservation Center",
    text: `Fine art galleries`,
  },
  {
    id: 3,
    icon: "/assets/Lagos.jpg",
    header: "Lagos City Tours",
    text: `Tours`,
  },
  {
    id: 4,
    icon: "/assets/park.jpg",
    header: "Freedom Park Lagos",
    text: `Historical sites`,
  },
  {
    id: 5,
    icon: "/assets/elegushi.png",
    header: "Elegushi Beach",
    text: `Beaches`,
  },
  {
    id: 6,
    icon: "/assets/museum.png",
    header: "National Museum Lagos",
    text: `Museums`,
  },
  {
    id: 7,
    icon: "/assets/omu.png",
    header: "Omu Resorts",
    text: `Entertainment`,
  },
  {
    id: 8,
    icon: "/assets/tarkwa.png",
    header: "Tarkwa Bay Beach",
    text: `Beaches`,
  },
];
