import React from "react";
import HowCard from "./../../common/HowCard";
import { how } from "./../../constants/how";

const How = () => {
  return (
    <div className="how">
      <h1>How we do it</h1>
      <div className="how-center">
        {how.map((item) => (
          <HowCard {...item} key={item.id} />
        ))}
      </div>
    </div>
  );
};

export default How;
