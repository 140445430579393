export const services = [
  {
    id: 1,
    icon: "/assets/services-one.png",
    header: "Air Ticketing",
    text: `Our airport desk is conveniently located at the JKIA passenger terminal.
        We offer personalized meet and greet services at the airport. This helps
        fast track clearance through immigration and custom.`,
  },
  {
    id: 2,
    icon: "/assets/services-two.png",
    header: "Hotel Bookings",
    text: `Our airport desk is conveniently located at the JKIA passenger terminal.
        We offer personalized meet and greet services at the airport. This helps
        fast track clearance through immigration and custom.`,
  },
  {
    id: 3,
    icon: "/assets/services-three.png",
    header: "Meet & Assist",
    text: `Our airport desk is conveniently located at the JKIA passenger terminal.
        We offer personalized meet and greet services at the airport. This helps
        fast track clearance through immigration and custom.`,
  },
  {
    id: 4,
    icon: "/assets/services-four.png",
    header: "Visa Advisory",
    text: `Our airport desk is conveniently located at the JKIA passenger terminal.
        We offer personalized meet and greet services at the airport. This helps
        fast track clearance through immigration and custom.`,
  },
  {
    id: 5,
    icon: "/assets/services-five.png",
    header: "Adventure Travel",
    text: `Our airport desk is conveniently located at the JKIA passenger terminal.
        We offer personalized meet and greet services at the airport. This helps
        fast track clearance through immigration and custom.`,
  },
  {
    id: 6,
    icon: "/assets/services-six.png",
    header: "Business Travel",
    text: `Our airport desk is conveniently located at the JKIA passenger terminal.
        We offer personalized meet and greet services at the airport. This helps
        fast track clearance through immigration and custom.`,
  },
];
