import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight, FaQuoteRight } from "react-icons/fa";
import data from "../../constants/people";

function Testimonial() {
  const [people] = useState(data);
  const [index, setIndex] = React.useState(0);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 6000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);

  return (
    <section className="testimonial-section">
      <div className="testimonial-title">
        <h3>TESTIMONIALS</h3>
        <h1 data-aos="fade-up" data-aos-once="true">
          What Our Customers Say
        </h1>
        <p>
          We have many happy customers that have booked holidays with us.Some
          Impresions from our Customers! Please read some of the lovely things
          our Customers say about us.
        </p>
      </div>

      <div className="testimonial-center">
        {people.map((person, personIndex) => {
          const { id, name, title, quote } = person;

          let position = "nextSlide";
          if (personIndex === index) {
            position = "activeSlide";
          }
          if (
            personIndex === index - 1 ||
            (index === 0 && personIndex === people.length - 1)
          ) {
            position = "lastSlide";
          }

          return (
            <main className={position} key={id}>
              {/* <img src={image} alt={name} className="person-img" /> */}
              <h4>{name}</h4>
              <p className="test-title">{title}</p>
              <p className="test-text">{quote}</p>
              <FaQuoteRight className="icon" />
            </main>
          );
        })}

        <div className="navigate-left">
          <FaChevronLeft className="pre" onClick={() => setIndex(index - 1)} />
        </div>

        <div className="navigate-right">
          <FaChevronRight className="nex" onClick={() => setIndex(index + 1)} />
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
