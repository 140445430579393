import React from "react";
import ServicesCard from "./../../common/ServicesCard";
import { services } from "./../../constants/services";

const Services = () => {
  return (
    <div className="services">
      <h3>OUR EXCLUSIVE OFFERS</h3>
      <p>
        We have the knowledge, experience, and expertise to take care of all
        your travel needs.Our Friendly and Professional staff can assist you
        with
      </p>

      <div className="services-center">
        {services.map((item) => (
          <ServicesCard {...item} key={item.id} />
        ))}
      </div>
    </div>
  );
};

export default Services;
