import React from "react";
import { Link } from "react-router-dom";

const Why = () => {
  return (
    <div className="why">
      <div className="why-left">
        <h3>HOW WE'RE DIFFERENT</h3>
        <h1>Why Book With Us?</h1>
        <p>
          Lakesi Global Travels have dominated the market for over 10 years
          giving consumers a good experience by attaching hidden fees, giving
          you a false sense of savings where there really is none due to the OTA
          agreements that are in place to make sure these companies can never
          compete against each other, and the use of predictive analytics to
          boost rates on your vacation based on how many times you search for a
          specific flight or click on a link.
        </p>

        <Link to="/contact">
          <button>Reach out today</button>
        </Link>
      </div>

      <div className="why-right">
        <img src="/assets/agent4.jpg" alt="" />
      </div>
    </div>
  );
};

export default Why;
