import React from "react";
import { Link } from "react-router-dom";

const Plan = () => {
  return (
    <div className="plan">
      <div className="plan-left">
        <img
          src="/assets/lakesi2.png"
          alt=""
          data-aos="zoom-in"
          data-aos-once="true"
        />
      </div>

      <div className="plan-right">
        <h2 data-aos="zoom-out" data-aos-once="true">
          Plan your trip with <br />
          <b>
            Lakesi <span>Global</span> Travels
          </b>
        </h2>

        <p data-aos="fade-down" data-aos-once="true">
          Take the unique chance to get the most unforgettable experience. These
          are the emotions you will never forget.
        </p>

        <Link to="/contact">
          <button data-aos="fade-right" data-aos-once="true">
            Reach out
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Plan;
