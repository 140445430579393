import React from "react";

const Card = ({ icon, header, text }) => {
  return (
    <div className="vacation-card">
      <div className="vacation-image">
        <img src={icon} alt="" />
      </div>

      <div className="vacation-div">
        <h2>{header}</h2>
        <small>{text}</small>
        <span>View more</span>
      </div>
    </div>
  );
};

export default Card;
