import React from "react";

const ServicesCard = ({ ...item }) => {
  const { icon, header, text } = item;
  return (
    <div className="services-card">
      <div className="services-card-img">
        <img src={icon} alt="" />
      </div>

      <h2>{header}</h2>
      {/* <small>{text}</small> */}
    </div>
  );
};

export default ServicesCard;
