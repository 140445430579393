import React from "react";
import { NavLink } from "react-router-dom";

const data = [
  {
    id: 1,
    text: "Home",
    url: "/",
  },
  {
    id: 3,
    text: "About Us",
    url: "about",
  },
  {
    id: 4,
    text: "Services",
    url: "services",
  },
  {
    id: 5,
    text: "Contact Us",
    url: "contact",
  },
];

const tempLinks = data.map((link) => {
  return (
    <li key={link.id}>
      <NavLink
        className={({ isActive }) => (isActive ? "active" : "")}
        to={link.url}
      >
        {link.text}
      </NavLink>
    </li>
  );
});

const New = ({ styleClass }) => {
  return (
    <ul className={`page-links ${styleClass ? styleClass : ""}`}>
      {tempLinks}
    </ul>
  );
};

export default New;
