const content = [
  {
    title: "Flight Booking Made Easy",
    description:
      "Save yourself the stress, let's do it for you. We help you plan pilgrimage to holy cities, passport & visa advisory",
    button: "Contact Us",
    image: "assets/hero-one.jpg",
  },
  {
    title: "Plan your trip and we book the flight for you.",
    description:
      "We always make our customers happy by providing as many choices as possible.",
    button: "Discover",
    image: "assets/hero-two.jpg",
  },
  {
    title: `Excellent services and lowest fares in airlines`,
    description:
      "We have always priortized the convenience of our users by providing low prices and with a easy process.",
    button: "Join Us Today",
    image: "assets/hero-three.jpg",
  },
  {
    title: "Plan your trip and we book the flight for you.",
    description:
      "We always make our customers happy by providing as many choices as possible.",
    button: "Discover",
    image: "assets/hero-four.jpg",
  },
  {
    title: "With our experience we will serve you.",
    description:
      "We have always priortized the convenience of our users by providing low prices and with a easy process.",
    button: "Join Us Today",
    image: "assets/hero-seven.jpg",
  },
];

export default content;
