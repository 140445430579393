import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Contact2 = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Onsubmit function
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      await axios.post("https://lakesitravel.herokuapp.com/api/send-mail", {
        name,
        email,
        message,
      });
      // e.target.reset();
      setName("");
      setEmail("");
      setMessage("");
      alert("Your message has been received, Thank you for contacting us");
      setLoading(false);

      e.target.reset();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="contact-form">
      <h1>Get in touch</h1>
      <p>
        If you have any kind of travel information don't hesitate to contact
        with us for immediate customer support. We would love to hear from you
      </p>

      <div className="main-form">
        <div className="main-form-left">
          <div className="form-left-div">
            <img src="/assets/contact-us.jpg" alt="" />
          </div>
        </div>

        <div className="main-form-right">
          <form onSubmit={handleSubmit} className="major-form">
            <div className="form-group">
              <label>Name</label>
              <span>
                <input
                  type="text"
                  value={name}
                  placeholder="Enter your name"
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </span>
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                value={email}
                placeholder="Enter your email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Message</label>
              <textarea
                value={message}
                placeholder="Enter your message here..."
                required
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            <button>{loading ? "SENDING..." : "SUBMIT"}</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact2;
