export const years = [
  {
    id: 1,
    icon: "/assets/about-one.png",
    header: "10",
    text: `Years Experience`,
  },
  {
    id: 2,
    icon: "/assets/about-two.png",
    header: "120",
    text: `Worldwide Coverage`,
  },
  {
    id: 3,
    icon: "/assets/about-three.png",
    header: "550",
    text: `Tour Operators`,
  },
  {
    id: 4,
    icon: "/assets/about-four.png",
    header: "2,500",
    text: `Cities available`,
  },
];
