import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

// COMPONENTS
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Router from "./routes/Router";
import ChatwootWidget from "./common/ChatwootWidget";
import Sidebar from "./components/navbar/Sidebar";

function App() {
  const [isOpen, setisOpen] = useState(false);
  const toggleSidebar = () => {
    setisOpen(!isOpen);
  };

  // THE SECTION OF THE AOS
  useEffect(() => {
    Aos.init({ duration: 1200 });
  }, []);

  return (
    <BrowserRouter>
      <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen} />
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <Router />
      <Footer />
      <ChatwootWidget />
    </BrowserRouter>
  );
}

export default App;
